import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AnalyticsLanguage } from '@landr/core';
import { withPrefix } from 'gatsby';
import { PageDataType } from 'templates/types';
import { getAlternateLink, getCanonicalLink, getTwoLetterLanguageCode, prismicText } from 'helpers';
import { PageContextType, PrismicLocalesEnum, SupportedLanguages } from 'types';
import { analyticsV2, SegmentService } from 'analytics';
import { PricingUris } from 'helpers/pricingLinkUtils';
import packageJSON from '../../../package.json';

export interface PageMetadataProps {
    page: PageDataType;
    pageContext: PageContextType;
    location: Location;
}

export const PageMetadata: React.FC<PageMetadataProps> = ({ page, pageContext, location }) => {
    const isPricingPage = Boolean(PricingUris.find((pricingUri) => location.pathname.includes(pricingUri)));

    // import smartbanner script (once only)
    useEffect(() => {
        if (!document.getElementById('smartbanner_script')) {
            const smartBannerScript = document.createElement('script');
            smartBannerScript.id = 'smartbanner_script';
            smartBannerScript.type = 'text/javascript';
            smartBannerScript.src = 'https://cdn.jsdelivr.net/npm/smartbanner.js@1.19.1/dist/smartbanner.min.js';
            smartBannerScript.async = true;
            smartBannerScript.defer = true;
            document.body.appendChild(smartBannerScript);
        }
    }, []);

    const {
        _meta: { alternateLanguages: alternateLanguages, lang },
        theme,
        title,
        meta_title: metaTitle,
        meta_description: metaDescription,
        meta_image: metaImage,
        meta_twitter_image: metaTwitterImage,
        meta_canonical: metaCanonical,
        page_type: pageType,
    } = page;

    const titleText = prismicText(metaTitle) || prismicText(title);
    const descriptionText = prismicText(metaDescription);

    // If there is an open graph image uploaded in Prismic, use that. Otherwise, fallback to the image in the
    // static/ folder
    const openGraphUrl = (metaImage && metaImage.url) || `${process.env.GATSBY_GUESTSITE_ENDPOINT}/og_image.jpg`;

    // If there is a twitter image specified in Prismic, use that, otherwise use the open graph image uploaded
    // in Prismic. If none of those images have been set, use the fallback image found in the static/ folder
    const twitterUrl =
        (metaTwitterImage && metaTwitterImage.url) ||
        (metaImage && metaImage.url) ||
        `${process.env.GATSBY_GUESTSITE_ENDPOINT}/twitter_image.jpg`;

    const canonicalUrl = getCanonicalLink(location, metaCanonical);
    const handlePageChange = useCallback(() => {
        // Kept for compatibility of analytics V1 events sending user properties `L - Page *`
        SegmentService.page(page);
        analyticsV2.page(getTwoLetterLanguageCode(lang) as AnalyticsLanguage);
        analyticsV2.convertExperiment.run();
    }, [page]);

    const englishAlternateLanguage = alternateLanguages.find((x) => x.lang === PrismicLocalesEnum.EnUs);
    // If undefined, then the current language must be English already
    const englishPageUid = englishAlternateLanguage ? englishAlternateLanguage.uid : pageContext.uid;
    const defaultAlternateUrl = `${process.env.GATSBY_GUESTSITE_ENDPOINT}${getAlternateLink({
        language: SupportedLanguages.en,
        uid: englishPageUid,
        pageType: pageType,
    })}`;
    const currentLanguageUrl = `${process.env.GATSBY_GUESTSITE_ENDPOINT}${getAlternateLink({
        language: lang,
        uid: pageContext.uid,
        pageType: pageType,
    })}`;

    return (
        <Helmet onChangeClientState={handlePageChange}>
            <html className={theme} lang={getTwoLetterLanguageCode(lang)} />
            <meta name="version" content={packageJSON.version} />
            {/* General tags */}
            <base href="/" />
            <title>{titleText}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta charSet="utf-8" />
            {/* Tell IE what version of IE the page should be rendered as */}
            <meta content="IE=edge,chrome=1" http-equiv="X-UA-Compatible" />
            {/* Associate a specific cryptographic public key with a certain web
            server to decrease the risk of MITM attacks with forged certificates */}
            <meta
                http-equiv="Public-Key-Pins"
                content="pin-sha256='sW+2XERgp5Lk1y2Z4EIm3nuXHcHx0ygDxnmWm8/ynSk='; max-age=2592000"
            />
            {/* Tell browser that page should only be accessed through HTTPS */}
            <meta http-equiv="Strict-Transport-Security" content="max-age=15552000; preload" />
            {/* Indicate that the MIME types advertised in the Content-Type
            headers should not be changed and be followed */}
            <meta http-equiv="X-Content-Type-Options" content="nosniff" />
            {/* Verify identity for Google Search Console */}
            <meta name="google-site-verification" content="i1YKmdWehqbFzzuhjEmdfnZmam2X_m0eTQbafHib7fU" />
            {/* Verify identity with Bing Webmaster Center */}
            <meta name="msvalidate.01" content="186B757CCC477822E5AEE78D13E890F9" />
            {/* SEO description */}
            {descriptionText && <meta name="description" content={descriptionText} />}
            {/* SEO canonical URL to indicate what page the SEO information should be pulled from.
                Used when there are two pages with the identical content and you need to tell search
                engines which page should be treated as the main page. */}
            <link rel="canonical" href={canonicalUrl} />
            {/* OpenGraph tags */}
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:title" content={titleText} />
            <meta property="og:type" content="website" />
            {descriptionText && <meta property="og:description" content={descriptionText} />}
            <meta property="og:image" content={openGraphUrl} />
            <meta property="og:site_name" content="LANDR" />
            {/* Twitter Card tags */}
            <meta name="twitter:title" content={titleText} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@LANDR_music" />
            {descriptionText && <meta name="twitter:description" content={descriptionText} />}
            <meta name="twitter:image" content={twitterUrl} />
            {/* Set an icon image when saving the page to an iOS homescreen. The -precomposed is appended to
            apple-touch-icon to support iOS versions 6 and under: https://stackoverflow.com/questions/20716490/apple-touch-icon-precomposed-is-not-a-real-value-for-the-rel-attribute */}
            <link rel="apple-touch-icon-precomposed" href={withPrefix('favicon-180.png')} />
            {/* Favicon */}
            <link rel="shortcut icon" href={withPrefix('favicon-32.png')} />
            {/* Language (content should take the form of en-CA) */}
            <meta http-equiv="content-language" content={lang} />
            {/* TODO: Allow a preferred language to be selected */}
            {/* Google Merchant Center Verification  */}
            <meta name="google-site-verification" content="ahTD-pG1dNhSHWT9qG5t1z4AptXno-5Jwk363yRqpeo" />
            <link rel="alternate" href={defaultAlternateUrl} hrefLang="x-default" />
            <link rel="alternate" href={currentLanguageUrl} hrefLang={getTwoLetterLanguageCode(lang)} />
            {alternateLanguages.map((item) => {
                const alternateLanguageUrl = `${process.env.GATSBY_GUESTSITE_ENDPOINT}${getAlternateLink({
                    language: item.lang,
                    uid: item.uid,
                    pageType: pageType,
                })}`;
                return (
                    <link
                        key={item.uid}
                        rel="alternate"
                        href={alternateLanguageUrl}
                        hrefLang={getTwoLetterLanguageCode(item.lang)}
                    />
                );
            })}

            {/* Corporate Contacts Information for Google Search */}
            <script type="application/ld+json">
                {`
                    {
                    "@context" : "http://schema.org",
                    "@type" : "Organization",
                    "name" : "LANDR",
                    "logo" : "https://www.landr.com/img/logo-landr.png",
                    "url" : "https://www.landr.com",
                    "telephone": "+1 514-840-9700",
                    "email": "mailto:support@landr.com",
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Montreal",
                        "addressRegion": "QC",
                        "addressCountry": "CA",
                        "postalCode":"H3B 4L4",
                        "streetAddress": "1001 Boulevard Robert-Bourassa"
                    },
                    "sameAs" : [
                        "https://twitter.com/LANDR_music",
                        "https://www.facebook.com/LANDRmusic",
                        "https://www.youtube.com/user/LANDRmastering",
                        "https://www.linkedin.com/company/mixgeni-us"
                        ]
                    }
                `}
            </script>
            {/* Unbounce Popups used by marketing */}
            {process.env.GATSBY_UNBOUNCE_URL !== 'null' && <script src={`${process.env.GATSBY_UNBOUNCE_URL}`} async />}

            {/* Start SmartBanner configuration */}
            <meta name="smartbanner:title" content="LANDR App" />
            <meta name="smartbanner:author" content="Messaging for musicians" />
            <meta name="smartbanner:price" content="FREE" />
            <meta name="smartbanner:price-suffix-apple" content=" - On the App Store" />
            <meta name="smartbanner:price-suffix-google" content=" - On Google Play" />
            <meta
                name="smartbanner:icon-apple"
                content="https://play-lh.googleusercontent.com/Vuf_ejgmoz6HKTZi8HEH8E577lWnAwzE4Tki2UFWQLoNdikRvjVj34WVhocqlhy_Auu2=w64-h64-rw"
            />
            <meta
                name="smartbanner:icon-google"
                content="https://play-lh.googleusercontent.com/Vuf_ejgmoz6HKTZi8HEH8E577lWnAwzE4Tki2UFWQLoNdikRvjVj34WVhocqlhy_Auu2=w64-h64-rw"
            />
            <meta name="smartbanner:button" content="View" />
            <meta name="smartbanner:button-url-apple" content="https://apps.apple.com/app/id6443542020" />
            <meta
                name="smartbanner:button-url-google"
                content="https://play.google.com/store/apps/details?id=com.landr.mobile.app"
            />
            <meta name="smartbanner:enabled-platforms" content={isPricingPage ? 'none' : 'android,ios'} />
            <meta name="smartbanner:close-label" content="Close" />
            <meta name="smartbanner:custom-design-modifier" content="landr" />
            <meta name="smartbanner:exclude-user-agent-regex" content="^.*(Windows NT|Intel Mac OS X).*$" />
            {/* End SmartBanner configuration */}
            {/* Yandex search engine verification to enable indexing */}
            <meta name="yandex-verification" content="3ef762e5284bf319" />

            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdn.jsdelivr.net/npm/smartbanner.js@1.19.1/dist/smartbanner.css"
            />

            <style type="text/css">{`
                .smartbanner--landr {
                    display: ${isPricingPage ? 'none' : 'block'};
                    font-family: 'Sailec',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
                    background: white;
                    box-shadow: none;
                }
                .smartbanner--landr .smartbanner__exit {
                    box-shadow: none;
                    background: transparent;
                }
                .smartbanner--landr .smartbanner__info {
                    line-height: 20px;
                    color: rgb(108 119 130);
                    text-shadow: none;
                }
                .smartbanner--landr .smartbanner__info__title {
                    color: rgb(20 39 57);
                    font-weight: 600;
                }
                .smartbanner--landr .smartbanner__info__price {
                    margin-top: 3px;
                }
                .smartbanner--landr .smartbanner__button {
                    background: transparent;
                    box-shadow: none;
                    right: 10px;
                }
                .smartbanner--landr .smartbanner__button__label {
                    color: #3b82f6;
                    font-size: 14px;
                    background: transparent;
                    font-weight: 600;
                }
            `}</style>
        </Helmet>
    );
};
