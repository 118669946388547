import { PrismicInternalLink } from 'components/Slices/types';
import { RichText } from 'prismic-dom';
import { Edges } from 'types';
import { PageType, PageTypeToPath } from 'templates/types';
import { getSanitizedURL } from './getSanitizedURL';
import { getTwoLetterLanguageCode } from './getTwoLetterLanguageCode';
import { isPlugin } from './isPluginPage';

const getQueryString = (link: PrismicInternalLink): string => {
    if (!link || !link.queryParams) {
        return '';
    }

    const params = (link && link.queryParams) || {};
    let queryString = '';

    Object.keys(params).forEach((param: string, i: number) => {
        const prefix = i === 0 ? '?' : '&';
        queryString += `${prefix}${param}=${params[param]}`;
    });

    return queryString;
};

export const getLangPathWithTrailingSlash = (lang: string): string => {
    return lang !== 'en-us' ? `${getTwoLetterLanguageCode(lang)}/` : '';
};

export function getPageTypeWithTrailingSlash(pageType: PageType) {
    if (!pageType || !PageTypeToPath[pageType]) {
        return '';
    } else {
        return `${PageTypeToPath[pageType]}/`;
    }
}

// Used to generate links to other Prismic pages using loaded Graphql data
export const linkResolver = (link: PrismicInternalLink): string => {
    if (link && link._meta) {
        const langPath = getLangPathWithTrailingSlash(link._meta.lang);
        const pluginSubPagePath = isPlugin(link.page_type) ? 'plugins/' : '';
        const { uid } = link._meta;
        const queryString = getQueryString(link);

        return getSanitizedURL(`/${langPath}${pluginSubPagePath}${uid}/${queryString}`);
    }
    return '';
};

// Prismic richtext field links don't have the same data structure.
type RichTextInternalLink = {
    lang: string;
    uid: string;
};
export const prismicLinkResolver = (link: RichTextInternalLink): string =>
    link ? `/${getLangPathWithTrailingSlash(link.lang)}${link.uid}/` : '';

// Links coming from Prismic's preview
type PreviewLink = {
    type: string;
    lang: string;
    uid: string;
    first_publication_date: string;
    alternate_languages: any[];
    data?: {
        page_type: PageType;
    };
};
export const previewLinkResolver = (link: PreviewLink) => {
    if (!link) {
        return null;
    }

    const { lang, type, uid, first_publication_date, data } = link;
    const shortLang = getTwoLetterLanguageCode(lang);

    // Note: I couldn't figure out how to detect unpublished pages here, so we don't redirect them to the correct url.
    // They can be previewed by manually heading to `/unpublished?uid=${link.uid}`.

    // Preview of an existing page.
    if (type === 'page') {
        // Homepage
        if (uid.startsWith('home-')) {
            return `/${shortLang}/`;
        }

        // Other pages
        if (first_publication_date === null) {
            // unpublished page
            return `/unpublished?uid=${uid}&lang=${lang}`;
        } else if (data && isPlugin(data.page_type)) {
            return `/${shortLang}/plugins/${uid}`;
        } else {
            return `/${shortLang}/${uid}`;
        }
    }

    // Preview of an existing 404 page.
    if (type === 'not_found_page') {
        return `/${shortLang}/404`;
    }

    // Preview of navigation
    if (type === 'main_navigation' || type === 'main_footer') {
        return `/${shortLang}/`;
    }

    if (type === 'waitzine_content') {
        if (window.location.hostname === 'local.landr.com') {
            return `https://local.landr.com:9102/library/prismicPreviewWaitzine`;
        } else {
            return `${process.env.GATSBY_APP_ENDPOINT}/library/prismicPreviewWaitzine`;
        }
    }

    // Unsupported document
    alert(`We only implemented previews for pages.\n\n Love, LANDR devs.`);
    return `/${shortLang}/`;
};

// Using this means a RichText field was chosen in Prismic, but should have been a KeyText field.
// TODO: MG-38000 Replace incorrectly chosen RichText fields with KeyText
export const prismicText = (content: any): string => {
    if (!content) {
        return '';
    }

    return typeof content === 'string' ? content : RichText.asText(content);
};

export const getDataForLang = <T extends Record<string, unknown>>(all: Edges<T>, lang: string): T | null => {
    if (!all || !all.edges || !all.edges.length) {
        return null;
    }

    const findEdge = (language: string) => {
        return all.edges.find((e: any) => e.node._meta.lang === language);
    };

    const edge = findEdge(lang) || findEdge('en-us');

    return edge ? edge.node : null;
};
